import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../environments/environment';
import {ActivatedRoute, Params} from '@angular/router';
import { Response, HttpModule, RequestOptions } from '@angular/http';
import { PasswordPolicy } from './pages/password_policy/password_policy';

@Injectable()

export class AppService {

  constructor(private http: Http, private router: Router, private _location: Location, private activatedRoute: ActivatedRoute) {
    // let copy_token = localStorage.getItem('copy_token');
    // if (copy_token != null) {
    //   this.upload_Header.push({name: 'Authorization', value: 'Bearer ' + localStorage.getItem('copy_token')});
    // }
    // else {
    //   let token:any = localStorage.getItem('auth_app_token');
    //   this.upload_Header.push({name: 'Authorization', value: 'Bearer ' + JSON.parse(token).value});
    // }
    if (localStorage.getItem('copy_token')) {
      this.upload_Header.push({name: 'Authorization', value: 'Bearer ' + localStorage.getItem('copy_token')});
    }
    else if (localStorage.getItem('auth_app_token')) {
      let token:any = localStorage.getItem('auth_app_token');
      this.upload_Header.push({name: 'Authorization', value: 'Bearer ' + JSON.parse(token).value});
    }
  }
  // headers for uploading the file
  upload_Header: Array<{
    name: string;
    value: string;
  }> = [];

  showMessages: any = {};
  public errors: string;
  public messages: string[] = [];
  public abc: any;
  public def: any;
  public err_code: string;
  public err_message: string;
  public INFO : string;
  public codes: any;
  public policy: any;

  public success_message: string;

  apiUrl= environment.API_URL;
  apiUrlUser= `${this.apiUrl}`;
  apiUrlContacts= `${this.apiUrl}/contacts`;
  apiUrlDocument= `${this.apiUrl}/documents`;
  apiUrlText= `${this.apiUrl}/texts`;
  apiUrlRecording= `${this.apiUrl}/recordings`;
  apiUrlTemplate= `${this.apiUrl}/templates`;
  apiUrlTransmission= `${this.apiUrl}/transmissions`;
  apiUrlPrograms= `${this.apiUrl}/programs`;
  apiUrlRegion = `${this.apiUrl}/region`;
  apiUrlCountry = `${this.apiUrl}/country`;
  apiUrlServices = `${this.apiUrl}/services`;
  apiUrlProviders = `${this.apiUrl}/providers`;
  apiUrlDestination = `${this.apiUrl}/destination`;
  apiUrlUsers = `${this.apiUrl}/users`;
  apiUrlGroups = `${this.apiUrl}/groups`;
  apiUrlCampaigns = `${this.apiUrl}/campaigns`;
  apiUrlDashboard = `${this.apiUrl}/statistics`;
  apiUrlAccounts = `${this.apiUrl}/accounts`;
  apiUrlDid = `${this.apiUrl}/dids`;
  apiUrlRoles = `${this.apiUrl}/roles`;
  apiUrlSpool = `${this.apiUrl}/spools`;
  apiUrlCDR = `${this.apiUrl}/cdr/csv`;
  apiUrlRates = `${this.apiUrl}/rates`;
  apiUrlPlans = `${this.apiUrl}/plans`;
  apiUrlPayments = `${this.apiUrl}/payments`;
  apiUrlRoutes = `${this.apiUrl}/routes`;
  apiUrlTenants = `${this.apiUrl}/tenants`;
  apiUrlBranding = `${this.apiUrl}/branding`;
  apiUrlSSO = `${this.apiUrl}/authenticate`;
  apiUrlForgotPassword = `${this.apiUrl}/forgot_password`;
  apiUrlUpdatePassword = `${this.apiUrl}/update_password`;
  apiUrlTokenPayload = `${this.apiUrl}/token_payload`;
  apiUrlCover = `${this.apiUrl}/coverpage`;
  apiUrlCovers = `${this.apiUrl}/coverpages`;
  apiUrlPasswordPolicy = `${this.apiUrl}/password_policy`;
  apiUrlAnnouncement = `${this.apiUrl}/announcement`;

  createAuthorizationHeader(headers: Headers) {
    let copy_token = localStorage.getItem('copy_token');
    if (copy_token != null) {
      headers.append('Authorization', ' Bearer ' + localStorage.getItem('copy_token'));
    }
    else {
      let token:any = localStorage.getItem('auth_app_token');
      headers.append('Authorization', ' Bearer ' + JSON.parse(token).value);
    }
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
  }

  get_Password_Policy(): any {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.codes = params.code;
    });
    const url = `${this.apiUrlPasswordPolicy}`;
    return this.http
    .get(url)
    .toPromise()
    .then((response) => response.json() as PasswordPolicy)
    .catch((response) => this.handleError(response));
  }

  loginAuthorizationHeader(headers:Headers) {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Accept', 'X-Auth-Token');
  }

  ForgotAuthorizationHeader(headers:Headers) {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
  }

  ResetAuthorizationHeader(headers:Headers) {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
  }

  public handleError(error: any): Promise<any> {
    // console.error('An error occurred', error); // for demo purposes only
    if (error.status === 0 || error.status === 500) {
      this.router.navigate(['pages/miscellaneous/404']);
    }
    if (error.status === 401) {
      // this.router.navigate(['auth/login']);
      this.router.navigate(['auth']);
    }
    if (error.status === 403) {
      this._location.back();
    }
    if (error.status === 404 ) {
      this.def = JSON.parse(error._body);
      this.err_code = this.def.error.code;
      // this.err_code = "403";
      this.err_message = this.def.error.message;
      this.abc = this.err_code + ', ' + this.err_message;
      this.errors = this.abc;
    }
    return Promise.reject(error.message || error);
  }

  public downloadError(error: any): Promise<any> {
    console.error('An error occurred', error);
    this.errors = error.status + ' ' + error.statusText;
    return Promise.reject(error.message || error);
  }

}
